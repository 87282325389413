import {
  keys, forEach, cloneDeep,
  reduce, map, find, includes,
  set, get, merge, isObject, toPairs,
  some, findIndex, filter, orderBy,
} from 'lodash';
import sportIds from '@/services/helpers/sports';

const {
  HOCKEY_ID,
  BASKETBALL_ID,
  FOOTBALL_ID,
  BASEBALL_ID,
} = sportIds;

// ICE HOCKEY
export const createHockeyGlobalParams = () => ({
  periodOneDistribution: {
    shortName: 'p1distr',
    longName: 'Period one distribution',
    defaultValue: 1.0,
    raiseIncrement: 0.01,
    minValue: 0.01,
    maxValue: 3.0,
  },
  periodTwoDistribution: {
    shortName: 'p2distr',
    longName: 'Period two distribution',
    defaultValue: 1.1,
    raiseIncrement: 0.01,
    minValue: 0.01,
    maxValue: 3.0,
  },
  periodThreeDistribution: {
    shortName: 'p3distr',
    longName: 'Period three distribution',
    defaultValue: 1.0,
    raiseIncrement: 0.01,
    minValue: 0.01,
    maxValue: 3.0,
  },
  overtimeDistribution: {
    shortName: 'OTdistr',
    longName: 'Overtime distribution',
    defaultValue: 2.17,
    raiseIncrement: 0.01,
    minValue: 0.01,
    maxValue: 3.0,
  },
});

export const createHockeyHomeTeamParams = () => ({
  scoreRateHome: {
    shortName: 'scrRate',
    longName: 'Score rate Home',
    defaultValue: 0.044,
    raiseIncrement: 0.001,
    minValue: 0.001,
    maxValue: 0.999,
    isMain: true,
    showOnMultiviewList: true,
  },
  winBoostHome: {
    shortName: 'winBoost',
    longName: 'Win boost Home',
    defaultValue: 1.0,
    raiseIncrement: 0.1,
    minValue: 0.1,
    maxValue: 3.0,
    showOnMultiviewList: true,
  },
  loseBoostHome: {
    shortName: 'loseBoost',
    longName: 'Lose boost Home',
    defaultValue: 1.0,
    raiseIncrement: 0.1,
    minValue: 0.1,
    maxValue: 3.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorHome54: {
    shortName: 'PPscr 5v4',
    longName: 'Power play score rate factor Home 5v4',
    defaultValue: 2.5,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorHome53: {
    shortName: 'PPscr 5v3',
    longName: 'Power play score rate factor Home 5v3',
    defaultValue: 7.2,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorHome43: {
    shortName: 'PPscr 4v3',
    longName: 'Power play score rate factor Home 4v3',
    defaultValue: 6.5,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  emptyNetRemainingMinutesHomeD3: {
    shortName: 'emptyNet D3+',
    longName: 'Empty net remaining minutes Home D3',
    defaultValue: 0,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 20,
  },
  emptyNetRemainingMinutesHomeD2: {
    shortName: 'emptyNet D2',
    longName: 'Empty net remaining minutes Home D2',
    defaultValue: 2.5,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 10.0,
  },
  emptyNetRemainingMinutesHomeD1: {
    shortName: 'emptyNet D1',
    longName: 'Empty net remaining minutes Home D1',
    defaultValue: 1.5,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 10.0,
  },
  emptyNetScoreRatePowerFactorHome: {
    shortName: 'emptyNet Pwr Scr',
    longName: 'Empty net score rate power factor Home',
    defaultValue: 5.8,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
  },
  shootoutScoreProbabilityHome: {
    shortName: 'SOscr',
    longName: 'Shootout score probability Home',
    defaultValue: 0.33,
    raiseIncrement: 0.001,
    minValue: 0.001,
    maxValue: 0.999,
  },
});

export const createHockeyAwayTeamParams = () => ({
  scoreRateAway: {
    shortName: 'scrRate',
    longName: 'Score rate Away',
    defaultValue: 0.044,
    raiseIncrement: 0.001,
    minValue: 0.001,
    maxValue: 0.999,
    isMain: true,
    showOnMultiviewList: true,
  },
  winBoostAway: {
    shortName: 'winBoost',
    longName: 'Win boost Away',
    defaultValue: 1.0,
    raiseIncrement: 0.1,
    minValue: 0.1,
    maxValue: 3.0,
    showOnMultiviewList: true,
  },
  loseBoostAway: {
    shortName: 'loseBoost',
    longName: 'Lose boost Away',
    defaultValue: 1.0,
    raiseIncrement: 0.1,
    minValue: 0.1,
    maxValue: 3.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorAway54: {
    shortName: 'PPscr 5v4',
    longName: 'Power play score rate factor Away 5v4',
    defaultValue: 2.5,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorAway53: {
    shortName: 'PPscr 5v3',
    longName: 'Power play score rate factor Away 5v3',
    defaultValue: 7.2,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  powerPlayScoreRateFactorAway43: {
    shortName: 'PPscr 4v3',
    longName: 'Power play score rate factor Away 4v3',
    defaultValue: 6.5,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
    showOnMultiviewList: true,
  },
  emptyNetRemainingMinutesAwayD3: {
    shortName: 'emptyNet D3+',
    longName: 'Empty net remaining minutes Away D3',
    defaultValue: 0,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 20,
  },
  emptyNetRemainingMinutesAwayD2: {
    shortName: 'emptyNet D2',
    longName: 'Empty net remaining minutes Away D2',
    defaultValue: 2.5,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 10.0,
  },
  emptyNetRemainingMinutesAwayD1: {
    shortName: 'emptyNet D1',
    longName: 'Empty net remaining minutes Away D1',
    defaultValue: 1.5,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 10.0,
  },
  emptyNetScoreRatePowerFactorAway: {
    shortName: 'emptyNet Pwr Scr',
    longName: 'Empty net score rate power factor Away',
    defaultValue: 5.8,
    raiseIncrement: 0.1,
    minValue: 1.0,
    maxValue: 20.0,
  },
  shootoutScoreProbabilityAway: {
    shortName: 'SOscr',
    longName: 'Shootout score probability Away',
    defaultValue: 0.33,
    raiseIncrement: 0.001,
    minValue: 0.001,
    maxValue: 0.999,
  },
});

export const createHockeyPlayerParams = () => ({
  goals: {
    key: 'goals',
    shortName: 'Goals',
    longName: 'Goals',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  assists: {
    key: 'assists',
    shortName: 'Assists',
    longName: 'Assists',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});

const hockeyParamsManagerMarkets = (isUSAView) => ([
  {
    label: 'Moneyline',
    marketCode: 'RESULT',
    order: 1,
  },
  {
    label: 'Spread',
    marketCode: 'GOAL_HANDICAP',
    acceptNegative: true,
    order: 2,
  },
  {
    label: 'Total',
    marketCode: 'GOAL_OVER_UNDER',
    order: 3,
  },
  {
    label: 'Home Team Total',
    marketCode: 'TEAM_GOAL_OVER_UNDER',
    params: {
      TEAM: 0,
    },
    order: isUSAView ? 5 : 4,
  },
  {
    label: 'Away Team Total',
    marketCode: 'TEAM_GOAL_OVER_UNDER',
    params: {
      TEAM: 1,
    },
    order: isUSAView ? 4 : 5,
  },
  {
    label: '1st Period Moneyline',
    marketCode: 'PERIOD_RESULT',
    params: {
      PERIOD: 1,
    },
    order: 6,
  },
  {
    label: '2nd Period Moneyline',
    marketCode: 'PERIOD_RESULT',
    params: {
      PERIOD: 2,
    },
    order: 7,
  },
  {
    label: '3rd Period Moneyline',
    marketCode: 'PERIOD_RESULT',
    params: {
      PERIOD: 3,
    },
    order: 8,
  },
]);

// BASKETBALL
export const getBasketballGlobalParamsModelType = (competitionType) => {
  if (competitionType === 'NBA' || competitionType === 'NCAA') {
    return 'cruncher';
  }
  return 'random-walk';
};
export const getBasketballGlobalParamsSportTypeByCompetition = (competitionType) => {
  if (competitionType === 'NBA' || competitionType === 'NCAA') {
    return 'basketball-cruncher';
  }
  return 'basketball';
};
export const createBasketballRandomWalkGlobalParams = () => ({
  mu: {
    shortName: 'MU',
    longName: 'Mean of distribution',
    defaultValue: 0.0370,
    raiseIncrement: 0.0001,
    minValue: 0.01,
    maxValue: 0.07,
    numberOfDecimals: 4,
  },
  intensityHome: {
    shortName: 'IntHome',
    longName: 'Intensity Home',
    defaultValue: 0.500,
    raiseIncrement: 0.005,
    minValue: 0.25,
    maxValue: 0.75,
  },
  comeback: {
    shortName: 'comeback',
    longName: 'Comeback',
    defaultValue: 0.090,
    raiseIncrement: 0.005,
    minValue: 0,
    maxValue: 0.25,
  },
  beta: {
    shortName: 'beta',
    longName: 'Beta',
    defaultValue: 0.001,
    raiseIncrement: 0.001,
    minValue: 0,
    maxValue: 0.02,
  },
  nq1: {
    shortName: 'NQ1',
    longName: '1st Quarter Distribution',
    defaultValue: 0.25,
    raiseIncrement: 0.01,
    minValue: 0.1,
    maxValue: 0.5,
  },
  nq2: {
    shortName: 'NQ2',
    longName: '2st Quarter Distribution',
    defaultValue: 0.25,
    raiseIncrement: 0.01,
    minValue: 0.1,
    maxValue: 0.5,
  },
  nq3: {
    shortName: 'NQ3',
    longName: '3st Quarter Distribution',
    defaultValue: 0.25,
    raiseIncrement: 0.01,
    minValue: 0.1,
    maxValue: 0.5,
  },
  homeTipOff: {
    shortName: 'Tipoff',
    longName: 'Home tip off',
    defaultValue: 0.5,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});
export const createBasketballRandomWalkHomeTeamParams = () => ({
  homeThreePointersPercentage: {
    shortName: 'H3PT%',
    longName: 'Home three pointers percentage',
    defaultValue: 0.3,
    raiseIncrement: 0.001,
    minValue: 0.01,
    maxValue: 0.99,
    isMain: true,
    showOnMultiviewList: true,
  },
});
export const createBasketballRandomWalkAwayTeamParams = () => ({
  awayThreePointersPercentage: {
    shortName: 'A3PT%',
    longName: 'Away three pointers percentage',
    defaultValue: 0.3,
    raiseIncrement: 0.001,
    minValue: 0.01,
    maxValue: 0.99,
    isMain: true,
    showOnMultiviewList: true,
  },
});
export const createBasketballCruncherGlobalParams = () => ({
  paceByHalf: {
    shortName: 'PaceH',
    longName: 'Pace by half',
    defaultValue: 0.005,
    raiseIncrement: 0.001,
    minValue: 0,
    maxValue: 1,
  },
  efficiencyByHalf: {
    shortName: 'effH',
    longName: 'Efficiency by half',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  freeThrowProbability: {
    shortName: 'Free Throw P',
    longName: 'Free throw probability',
    defaultValue: 0.77,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  homeFieldAdvantage: {
    shortName: 'Home field adv',
    longName: 'Home field advantage',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  ww: {
    shortName: 'ww',
    longName: 'WW',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  affectedLead: {
    shortName: 'aff lead',
    longName: 'WW',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  leverage: {
    shortName: 'leverage',
    longName: 'Leverage',
    defaultValue: 2.5,
    raiseIncrement: 0.1,
    minValue: 0,
    maxValue: 5,
  },
  homeTipOff: {
    shortName: 'Tipoff',
    longName: 'Home tip off',
    defaultValue: 0.5,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});
export const createBasketballCruncherHomeTeamParams = () => ({
  homeTeamEfficiency: {
    shortName: 'homeEff',
    longName: 'Home team efficiency',
    defaultValue: 1.08,
    raiseIncrement: 0.001,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  homeTeamPace: {
    shortName: 'homePace',
    longName: 'Home team pace',
    defaultValue: 0.98,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  homeTeamFoul: {
    shortName: 'homeFoul',
    longName: 'Home team foul',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  homeTeamVariance: {
    shortName: 'homeVariance',
    longName: 'Home team variance',
    defaultValue: 1.15,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  homeTeamFoulAggressiveness: {
    shortName: 'homeAggressiveness',
    longName: 'Home team foul aggressiveness',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  homeTeamUncertainty: {
    shortName: 'homeUncertainty',
    longName: 'Home team uncertainty',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    isMain: true,
    showOnMultiviewList: true,
  },
});
export const createBasketballCruncherAwayTeamParams = () => ({
  awayTeamEfficiency: {
    shortName: 'awayEff',
    longName: 'Away team efficiency',
    defaultValue: 1.08,
    raiseIncrement: 0.001,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  awayTeamPace: {
    shortName: 'awayPace',
    longName: 'Away team pace',
    defaultValue: 0.98,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  awayTeamFoul: {
    shortName: 'awayFoul',
    longName: 'Away team foul',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  awayTeamVariance: {
    shortName: 'awayVariance',
    longName: 'Away team variance',
    defaultValue: 1.15,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  awayTeamFoulAggressiveness: {
    shortName: 'awayAggressiveness',
    longName: 'Away team foul aggressiveness',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
    isMain: true,
    showOnMultiviewList: true,
  },
  awayTeamUncertainty: {
    shortName: 'awayUncertainty',
    longName: 'Away team uncertainty',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    isMain: true,
    showOnMultiviewList: true,
  },
});

export const createBasketballPlayerParams = () => ({
  star: {
    key: 'star',
    shortName: 'Star',
    longName: 'Star',
    defaultValue: false,
  },
  time: {
    key: 'time',
    shortName: 'Minutes',
    longName: 'Minutes',
    defaultValue: 0,
    raiseIncrement: 1,
    minValue: 0,
    maxValue: 48,
  },
  points: {
    key: 'points',
    shortName: 'Points',
    longName: 'Points',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  threes: {
    key: 'threes',
    shortName: 'Threes',
    longName: 'Threes',
    defaultValue: 0,
    raiseIncrement: 0.005,
    minValue: 0,
    maxValue: 1,
  },
  rebounds: {
    key: 'rebounds',
    shortName: 'Rebounds',
    longName: 'Rebounds',
    defaultValue: 0,
    raiseIncrement: 0.005,
    minValue: 0,
    maxValue: 1,
  },
  assists: {
    key: 'assists',
    shortName: 'Assists',
    longName: 'Assists',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});

const basketballParamsManagerMarkets = (isUSAView) => ([
  {
    label: 'Moneyline',
    marketCode: 'RESULT',
    order: 1,
  },
  {
    label: 'Spread',
    marketCode: 'POINT_HANDICAP',
    acceptNegative: true,
    order: 2,
  },
  {
    label: 'Total',
    marketCode: 'POINT_OVER_UNDER',
    order: 3,
  },
  {
    label: '1st Half Moneyline',
    marketCode: 'HALF_RESULT',
    params: {
      HALF: 1,
    },
    order: 4,
  },
  {
    label: '1st Half Total',
    marketCode: 'HALF_POINT_OVER_UNDER',
    params: {
      HALF: 1,
    },
    order: 5,
  },
  {
    label: '1st Half Spread',
    marketCode: 'HALF_POINT_HANDICAP',
    params: {
      HALF: 1,
    },
    acceptNegative: true,
    order: 6,
  },
  {
    label: 'Home Team Total',
    marketCode: 'TEAM_POINT_OVER_UNDER',
    params: {
      TEAM: 0,
    },
    order: isUSAView ? 8 : 7,
  },
  {
    label: 'Away Team Total',
    marketCode: 'TEAM_POINT_OVER_UNDER',
    params: {
      TEAM: 1,
    },
    order: isUSAView ? 7 : 8,
  },
]);

// AMERICAN FOOTBALL
export const createFootballGlobalParams = () => ({
  effByHalf: {
    shortName: 'effByHalf',
    longName: 'Efficiency by half',
    defaultValue: 0.0,
    raiseIncrement: 0.01,
    minValue: -1.0,
    maxValue: 1.0,
  },
  effScores: {
    shortName: 'effScores',
    longName: 'Efficiency scores',
    defaultValue: 0,
    raiseIncrement: 1,
    minValue: 0,
    maxValue: 5,
  },
  paceByHalf: {
    shortName: 'paceByHalf',
    longName: 'Pace by half',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -0.5,
    maxValue: 0.5,
  },
  paceRatio: {
    shortName: 'paceRatio',
    longName: 'Pace ratio',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  totalAdj: {
    shortName: 'totalAdj',
    longName: 'Total adjustement',
    defaultValue: 0,
    raiseIncrement: 0.1,
    minValue: -10,
    maxValue: 10,
  },
  homeFieldAdvantage: {
    shortName: 'homeFieldAdv',
    longName: 'Home field advantage',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -9,
    maxValue: 9,
  },
  coinFlip: {
    shortName: 'coinFlip',
    longName: 'Coin flip probability',
    defaultValue: 0.5,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  overrideDownNumber: {
    shortName: 'down',
    longName: 'Override down number',
    defaultValue: 1,
    raiseIncrement: 1,
    minValue: 1,
    maxValue: 4,
    hasDivider: true,
    availableInMatchStates: ['LIVE'],
  },
  overrideYardsToWinDown: {
    shortName: 'ytg',
    longName: 'Override yards to win down',
    defaultValue: 1,
    raiseIncrement: 1,
    minValue: 1,
    maxValue: 50,
    availableInMatchStates: ['LIVE'],
  },
  overrideYardsToEndZone: {
    shortName: 'ytt',
    longName: 'Override yards to end zone',
    defaultValue: 1,
    raiseIncrement: 1,
    minValue: 1,
    maxValue: 99,
    availableInMatchStates: ['LIVE'],
  },
  overridePossession: {
    shortName: 'In possesion',
    longName: 'Override possesion',
    options: [
      {
        label: 'Away',
        value: 'AWAY',
      },
      {
        label: 'Home',
        value: 'HOME',
      },
    ],
    type: 'text-toggle',
    availableInMatchStates: ['LIVE'],
  },
  overrideEventState: {
    longName: 'Include values in calculations',
    defaultValue: false,
    type: 'boolean',
    availableInMatchStates: ['LIVE'],
  },
});
export const createFootballHomeTeamParams = () => ({
  homeSuccessRate: {
    shortName: 'successRate',
    longName: 'Home success rate',
    defaultValue: 0.7,
    raiseIncrement: 0.005,
    minValue: 0.3,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  homeYardsPerFirst: {
    shortName: 'yardsPerFirst',
    longName: 'Home yards per first',
    defaultValue: 17,
    raiseIncrement: 0.1,
    minValue: 10.1,
    maxValue: 30,
    showOnMultiviewList: true,
  },
  homeTurnoverFactor: {
    shortName: 'TOFactor',
    longName: 'Home turnover factor',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
  },
  homeFourthDownAggression: {
    shortName: '4thdownAgg',
    longName: 'Home fourth down aggression',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 1,
    maxValue: 2,
  },
  homeFg33: {
    shortName: 'fg33',
    longName: 'Home field goal 33',
    defaultValue: 0.94,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  homeFg50: {
    shortName: 'fg50',
    longName: 'Home field goal 50',
    defaultValue: 0.75,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  homePuntDistance: {
    shortName: 'puntDist',
    longName: 'Home punt distance',
    defaultValue: 0,
    raiseIncrement: 0.1,
    minValue: -20,
    maxValue: 20,
  },
  homePuntFgYardLine: {
    shortName: 'puntFgYardL',
    longName: 'Home punt field goal yard line',
    defaultValue: 40,
    raiseIncrement: 1,
    minValue: 20,
    maxValue: 50,
  },
  homeSecsPerPlay: {
    shortName: 'secsPerPlay',
    longName: 'Home seconds per play',
    defaultValue: 1.02,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
    showOnMultiviewList: true,
  },
  homeP2pt: {
    shortName: 'p2pt',
    longName: 'Home probability 2 point conversion',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  homeEffQ3: {
    shortName: 'effQ3',
    longName: 'Home effeciency Q3',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
  },
  homeEffQ4: {
    shortName: 'effQ4',
    longName: 'Home effeciency Q4',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
  },
  homeGoForIt: {
    shortName: 'goForIt',
    longName: 'Home go for it',
    defaultValue: -1,
    raiseIncrement: 0.01,
    minValue: -1,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  homeLastDriveEff: {
    shortName: 'lastDriveEff',
    longName: 'Home last drive eff',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -0.5,
    maxValue: 0.5,
  },
  homeUncertainty: {
    shortName: 'UC',
    longName: 'Home uncertainty',
    defaultValue: 1.5,
    raiseIncrement: 0.01,
    minValue: 1,
    maxValue: 2,
  },
});
export const createFootballAwayTeamParams = () => ({
  awaySuccessRate: {
    shortName: 'successRate',
    longName: 'Away success rate',
    defaultValue: 0.7,
    raiseIncrement: 0.005,
    minValue: 0.3,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  awayYardsPerFirst: {
    shortName: 'yardsPerFirst',
    longName: 'Away yards per first',
    defaultValue: 17,
    raiseIncrement: 0.1,
    minValue: 10.1,
    maxValue: 30,
    showOnMultiviewList: true,
  },
  awayTurnoverFactor: {
    shortName: 'TOFactor',
    longName: 'Away turnover factor',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
  },
  awayFourthDownAggression: {
    shortName: '4thdownAgg',
    longName: 'Away fourth down aggression',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 1,
    maxValue: 2,
  },
  awayFg33: {
    shortName: 'fg33',
    longName: 'Away field goal 33',
    defaultValue: 0.94,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  awayFg50: {
    shortName: 'fg50',
    longName: 'Away field goal 50',
    defaultValue: 0.75,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  awayPuntDistance: {
    shortName: 'puntDist',
    longName: 'Away punt distance',
    defaultValue: 0,
    raiseIncrement: 0.1,
    minValue: -20,
    maxValue: 20,
  },
  awayPuntFgYardLine: {
    shortName: 'puntFgYardL',
    longName: 'Away punt field goal yard line',
    defaultValue: 40,
    raiseIncrement: 1,
    minValue: 20,
    maxValue: 50,
  },
  awaySecsPerPlay: {
    shortName: 'secsPerPlay',
    longName: 'Away seconds per play',
    defaultValue: 1.02,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
    showOnMultiviewList: true,
  },
  awayP2pt: {
    shortName: 'p2pt',
    longName: 'Away probability 2 point conversion',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  awayEffQ3: {
    shortName: 'effQ3',
    longName: 'Away effeciency Q3',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
  },
  awayEffQ4: {
    shortName: 'effQ4',
    longName: 'Away effeciency Q4',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0.5,
    maxValue: 1.5,
  },
  awayGoForIt: {
    shortName: 'goForIt',
    longName: 'Away go for it',
    defaultValue: -1,
    raiseIncrement: 0.01,
    minValue: -1,
    maxValue: 1,
    showOnMultiviewList: true,
  },
  awayLastDriveEff: {
    shortName: 'lastDriveEff',
    longName: 'Away last drive eff',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -0.5,
    maxValue: 0.5,
  },
  awayUncertainty: {
    shortName: 'UC',
    longName: 'Away uncertainty',
    defaultValue: 1.5,
    raiseIncrement: 0.01,
    minValue: 1,
    maxValue: 2,
  },
});
const footballParamsManagerMarkets = (isUSAView) => ([
  {
    label: 'Moneyline',
    marketCode: 'RESULT',
    order: 1,
  },
  {
    label: 'Spread',
    marketCode: 'POINT_HANDICAP',
    acceptNegative: true,
    order: 2,
  },
  {
    label: 'Total',
    marketCode: 'POINT_OVER_UNDER',
    order: 3,
  },
  {
    label: '1st Half Moneyline',
    marketCode: 'HALF_RESULT',
    params: {
      HALF: 1,
    },
    order: 4,
  },
  {
    label: '1st Half Total',
    marketCode: 'HALF_POINT_OVER_UNDER',
    params: {
      HALF: 1,
    },
    order: 5,
  },
  {
    label: '1st Half Spread',
    marketCode: 'HALF_POINT_HANDICAP',
    params: {
      HALF: 1,
    },
    acceptNegative: true,
    order: 6,
  },
  {
    label: 'Home Team Total',
    marketCode: 'TEAM_POINT_OVER_UNDER',
    params: {
      TEAM: 0,
    },
    order: isUSAView ? 8 : 7,
  },
  {
    label: 'Away Team Total',
    marketCode: 'TEAM_POINT_OVER_UNDER',
    params: {
      TEAM: 1,
    },
    order: isUSAView ? 7 : 8,
  },
]);
export const createAFPlayerQuarterbackParams = () => ({
  passingRunningRatio: {
    key: 'passingRunningRatio',
    shortName: 'Passing running ratio',
    longName: 'Passing running ratio',
    defaultValue: 0.57,
    raiseIncrement: 0.01,
    minValue: 0.47,
    maxValue: 0.67,
  },
  passingTouchdownFactor: {
    key: 'passingTouchdownFactor',
    shortName: 'Passing TD factor',
    longName: 'Passing touchdown factor',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -1,
    maxValue: 1,
  },
  passingCompletionFactor: {
    key: 'passingCompletionFactor',
    shortName: 'Passing comp factor',
    longName: 'Passing completion factor',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: -1,
    maxValue: 1,
  },
  passingYardsFactor: {
    key: 'passingYardsFactor',
    shortName: 'Passing yards factor',
    longName: 'Passing yards factor',
    defaultValue: 1,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 2,
  },
  interceptionTurnoverRatio: {
    key: 'interceptionTurnoverRatio',
    shortName: 'Interception turnover ratio',
    longName: 'Interception turnover ratio',
    defaultValue: 0.585,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runShortNoScore: {
    key: 'runShortNoScore',
    shortName: 'Run short no score',
    longName: 'Run short no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runLongNoScore: {
    key: 'runLongNoScore',
    shortName: 'Run long no score',
    longName: 'Run long no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runShortScore: {
    key: 'runShortScore',
    shortName: 'Run short score',
    longName: 'Run short score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runLongScore: {
    key: 'runLongScore',
    shortName: 'Run long score',
    longName: 'Run long score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});

export const createAFPlayerNonQuarterbackParams = () => ({
  receiveShortNoScore: {
    key: 'receiveShortNoScore',
    shortName: 'Receive short no score',
    longName: 'Receive short no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  receiveLongNoScore: {
    key: 'receiveLongNoScore',
    shortName: 'Receive long no score',
    longName: 'Receive long no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  receiveShortScore: {
    key: 'receiveShortScore',
    shortName: 'Receive short score',
    longName: 'Receive short score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  receiveLongScore: {
    key: 'receiveLongScore',
    shortName: 'Receive long score',
    longName: 'Receive long score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runShortNoScore: {
    key: 'runShortNoScore',
    shortName: 'Run short no score',
    longName: 'Run short no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runLongNoScore: {
    key: 'runLongNoScore',
    shortName: 'Run long no score',
    longName: 'Run long no score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runShortScore: {
    key: 'runShortScore',
    shortName: 'Run short score',
    longName: 'Run short score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
  runLongScore: {
    key: 'runLongScore',
    shortName: 'Run long score',
    longName: 'Run long score',
    defaultValue: 0,
    raiseIncrement: 0.01,
    minValue: 0,
    maxValue: 1,
  },
});

export const getAFMarketCodesByType = ({
  markets, availableMarketsPlayerTypes, isQuarterback, isOffensiveNonQB, isKicker,
}) => filter(markets, (market) => includes(availableMarketsPlayerTypes[market.marketCode], (
  // eslint-disable-next-line no-nested-ternary
  isQuarterback ? 'QUARTERBACK' : isOffensiveNonQB ? 'OFFENSIVE_NON_QB' : isKicker ? 'KICKER' : ''
))) || [];

// BASEBALL
export const createBaseballGlobalParams = (isCalculatedMode) => ({
  weatherData: {
    rhoStart: {
      shortName: 'app',
      longName: 'APP RHO Start',
      raiseIncrement: 0.001,
      minValue: 0,
      maxValue: 3,
      readonly: true,
    },
    rhoStartManual: {
      shortName: 'manual',
      longName: 'Manual RHO Start',
      raiseIncrement: 0.001,
      minValue: 0,
      maxValue: 3,
      customMappableKey: 'weatherData.rhoStart',
      readonly: isCalculatedMode,
    },
    rhoEnd: {
      shortName: 'app',
      longName: 'APP RHO End',
      raiseIncrement: 0.001,
      minValue: 0,
      maxValue: 3,
      readonly: true,
    },
    rhoEndManual: {
      shortName: 'manual',
      longName: 'Manual RHO End',
      raiseIncrement: 0.001,
      minValue: 0,
      maxValue: 3,
      customMappableKey: 'weatherData.rhoEnd',
      readonly: isCalculatedMode,
    },
  },
  homeRunRate: {
    shortName: 'hrr',
    longName: 'Home run rate',
    defaultValue: 0.034,
    raiseIncrement: 0.001,
    minValue: 0,
    maxValue: 1,
    readonly: isCalculatedMode,
  },
  useHomeRunRate: {
    defaultValue: true,
    label: 'Use home run rate',
    type: 'boolean',
    readonly: isCalculatedMode,
  },
  parkId: {
    shortName: 'parkId',
    type: 'select',
    readonly: isCalculatedMode,
  },
  priceMicros: {
    shortName: 'priceMicros',
  },
  projections: {
    league: {
      title: {
        text: 'League',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    park: {
      title: {
        text: 'Park',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    homeTeam: {
      title: {
        text: 'Home',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    awayTeam: {
      title: {
        text: 'Away',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    leftieToLeftie: {
      title: {
        text: 'Leftie to Leftie',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    leftieToRightie: {
      title: {
        text: 'Leftie to Rightie',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    rightieToRightie: {
      title: {
        text: 'Rightie to Rightie',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
    rightieToLeftie: {
      title: {
        text: 'Rightie to Leftie',
        nonMappable: true,
      },
      walkProb: {
        shortName: 'walk p',
        longName: 'Walk probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      strikeOutProb: {
        shortName: 'strkout p',
        longName: 'Strike out probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      singleProb: {
        shortName: 'single p',
        longName: 'Single probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      doubleOrTripleProb: {
        shortName: 'd or t p',
        longName: 'Double or triple probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
      homeRunProb: {
        shortName: 'hr p',
        longName: 'Home run probability',
        defaultValue: 0,
        raiseIncrement: 0.001,
        minValue: 0,
        maxValue: 1,
      },
    },
  },
});
export const createBaseballHomeTeamParams = () => ({
  pitcherAdjustments: {
    homeStarting: {
      shortName: 'Home s',
      longName: 'Home starting',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
    homeRelief: {
      shortName: 'Home r',
      longName: 'Home relief',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
  },
  uncertainty: {
    home: {
      shortName: 'uncertain h',
      longName: 'Home uncertainty',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
  },
});
export const createBaseballAwayTeamParams = () => ({
  pitcherAdjustments: {
    awayStarting: {
      shortName: 'Away s',
      longName: 'Away starting',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
    awayRelief: {
      shortName: 'Away r',
      longName: 'Away relief',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
  },
  uncertainty: {
    away: {
      shortName: 'Uncertain a',
      longName: 'Away uncertainty',
      defaultValue: 1,
      raiseIncrement: 0.01,
      minValue: 0,
      maxValue: 2,
    },
  },
});
const baseballParamsManagerMarkets = (isUSAView) => ([
  {
    label: 'Moneyline',
    marketCode: 'RESULT',
    order: 1,
  },
  {
    label: 'Spread',
    marketCode: 'RUN_HANDICAP',
    acceptNegative: true,
    order: 2,
  },
  {
    label: 'Total',
    marketCode: 'RUN_OVER_UNDER',
    order: 3,
  },
  {
    label: 'Home Team Total',
    marketCode: 'TEAM_RUN_OVER_UNDER',
    params: {
      TEAM: 0,
    },
    order: isUSAView ? 5 : 4,
  },
  {
    label: 'Away Team Total',
    marketCode: 'TEAM_RUN_OVER_UNDER',
    params: {
      TEAM: 1,
    },
    order: isUSAView ? 4 : 5,
  },
  {
    label: '1st Half ML',
    marketCode: 'RESULT_AFTER',
    params: {
      RESULT_AFTER_X: 5,
    },
    order: 6,
  },
  {
    label: '1st Half Total',
    marketCode: 'RUN_OVER_UNDER_AFTER',
    params: {
      RESULT_AFTER_X: 5,
    },
    order: 7,
  },
  {
    label: '1st Half Spread',
    marketCode: 'RUN_HANDICAP_AFTER',
    params: {
      RESULT_AFTER_X: 5,
    },
    order: 7,
  },
]);

export const createBaseballPlayerPitcherParams = () => ({
  isCurrentPitcher: {
    key: 'isCurrentPitcher',
    shortName: 'is current p',
    longName: 'Is current pitcher',
    defaultValue: false,
  },
  isNextPitcher: {
    key: 'isNextPitcher',
    shortName: 'is next p',
    longName: 'Is next pitcher',
    defaultValue: false,
  },
  maxPitchCount: {
    key: 'maxPitchCount',
    shortName: 'max pitch c',
    longName: 'Max pitch count',
    defaultValue: 30,
    raiseIncrement: 1,
    minValue: 0,
    maxValue: 500,
  },
  reliefPitcherOrder: {
    key: 'reliefPitcherOrder',
    shortName: 'r pitch order',
    longName: 'Relief pitcher order',
    defaultValue: 0,
    raiseIncrement: 1,
    minValue: 1,
    maxValue: 50,
  },
  leftHandedHomeRunProb: {
    key: 'leftHandedHomeRunProb',
    shortName: 'slhr',
    longName: 'Left handed home run prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.homeRunProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedStrikeOutProb: {
    key: 'leftHandedStrikeOutProb',
    shortName: 'slk',
    longName: 'Left handed strike out prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.strikeOutProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedSingleProb: {
    key: 'leftHandedSingleProb',
    shortName: 'slsing',
    longName: 'Left handed single prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.singleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedDoubleOrTripleProb: {
    key: 'leftHandedDoubleOrTripleProb',
    shortName: 'slxb',
    longName: 'Left handed double or triple prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.doubleOrTripleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedHomeRunProb: {
    key: 'rightHandedHomeRunProb',
    shortName: 'srhr',
    longName: 'Right handed home run prob ',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.homeRunProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedStrikeOutProb: {
    key: 'rightHandedStrikeOutProb',
    shortName: 'srk',
    longName: 'Right handed strike out prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.strikeOutProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedSingleProb: {
    key: 'rightHandedSingleProb',
    shortName: 'srsing',
    longName: 'Right handed single prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.singleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedDoubleOrTripleProb: {
    key: 'rightHandedDoubleOrTripleProb',
    shortName: 'srxb',
    longName: 'Right handed double or triple prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.doubleOrTripleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  againstRightHandedEff: {
    key: 'againstRightHandedEff',
    shortName: 'srw %',
    longName: 'Against right handed %',
    defaultValue: 0,
    readonly: true,
  },
  againstLeftHandedEff: {
    key: 'againstLeftHandedEff',
    shortName: 'slw %',
    longName: 'Against left handed %',
    defaultValue: 0,
    readonly: true,
  },
});

export const createBaseballPlayerHitterParams = () => ({
  isCatcher: {
    key: 'isCatcher',
    shortName: 'is catcher',
    longName: 'Is catcher',
    defaultValue: false,
  },
  lineupNumber: {
    key: 'lineupNumber',
    shortName: 'lineup num',
    longName: 'Lineup number',
    defaultValue: 0,
    raiseIncrement: 1,
    minValue: 1,
    maxValue: 50,
  },
  leftHandedHomeRunProb: {
    key: 'leftHandedHomeRunProb',
    shortName: 'slhr',
    longName: 'Left handed home run prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.homeRunProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedStrikeOutProb: {
    key: 'leftHandedStrikeOutProb',
    shortName: 'slk',
    longName: 'Left handed strike out prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.strikeOutProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedSingleProb: {
    key: 'leftHandedSingleProb',
    shortName: 'slsing',
    longName: 'Left handed single prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.singleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  leftHandedDoubleOrTripleProb: {
    key: 'leftHandedDoubleOrTripleProb',
    shortName: 'slxb',
    longName: 'Left handed double or triple prob',
    defaultValue: 0,
    updateKey: 'leftHandedProjection.doubleOrTripleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedHomeRunProb: {
    key: 'rightHandedHomeRunProb',
    shortName: 'srhr',
    longName: 'Right handed home run prob ',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.homeRunProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedStrikeOutProb: {
    key: 'rightHandedStrikeOutProb',
    shortName: 'srk',
    longName: 'Right handed strike out prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.strikeOutProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedSingleProb: {
    key: 'rightHandedSingleProb',
    shortName: 'srsing',
    longName: 'Right handed single prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.singleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  rightHandedDoubleOrTripleProb: {
    key: 'rightHandedDoubleOrTripleProb',
    shortName: 'srxb',
    longName: 'Right handed double or triple prob',
    defaultValue: 0,
    updateKey: 'rightHandedProjection.doubleOrTripleProb',
    minValue: 0,
    maxValue: 100,
    raiseIncrement: 0.01,
  },
  againstRightHandedEff: {
    key: 'againstRightHandedEff',
    shortName: 'srw %',
    longName: 'Against right handed %',
    defaultValue: 0,
    readonly: true,
  },
  againstLeftHandedEff: {
    key: 'againstLeftHandedEff',
    shortName: 'slw %',
    longName: 'Against left handed %',
    defaultValue: 0,
    readonly: true,
  },
});

export const getBaseballPlayerMarketCodesByType = ({ markets, isPitcher, isHitter }) => {
  let marketCodesByType = [];
  if (isPitcher) {
    marketCodesByType = [
      ...marketCodesByType,
      ...['PLAYER_STRIKEOUT_OVER_UNDER'],
    ];
  }
  if (isHitter) {
    marketCodesByType = [
      ...marketCodesByType,
      ...['PLAYER_BASES_OVER_UNDER', 'PLAYER_RUN_OVER_UNDER', 'PLAYER_ON_BASE', 'PLAYER_ON_BASE_TWO_PLUS', 'PLAYER_HOME_RUN'],
    ];
  }

  return filter(markets, (market) => includes(marketCodesByType, market.marketCode));
};

// MAPPERS
export const getGlobalParamsConfigBySport = (sportId, modelType) => {
  let globalParamsConfig = {};

  switch (sportId) {
  case HOCKEY_ID: {
    globalParamsConfig = createHockeyGlobalParams();
    break;
  }
  case BASKETBALL_ID: {
    globalParamsConfig = modelType === 'cruncher' ? createBasketballCruncherGlobalParams() : createBasketballRandomWalkGlobalParams();
    break;
  }
  case FOOTBALL_ID: {
    globalParamsConfig = createFootballGlobalParams();
    break;
  }
  case BASEBALL_ID: {
    globalParamsConfig = createBaseballGlobalParams();
    break;
  }
  default: globalParamsConfig = null;
  }

  return globalParamsConfig;
};

export const getTeamParamsConfigBySport = (sportId, modelType) => {
  let teamParamsConfig = {};

  switch (sportId) {
  case HOCKEY_ID: {
    teamParamsConfig = {
      ...createHockeyHomeTeamParams(),
      ...createHockeyAwayTeamParams(),
    };
    break;
  }
  case FOOTBALL_ID: {
    teamParamsConfig = {
      ...createFootballHomeTeamParams(),
      ...createFootballAwayTeamParams(),
    };
    break;
  }
  case BASKETBALL_ID: {
    teamParamsConfig = {
      ...(modelType === 'cruncher' ? createBasketballCruncherHomeTeamParams() : createBasketballRandomWalkHomeTeamParams()),
      ...(modelType === 'cruncher' ? createBasketballCruncherAwayTeamParams() : createBasketballRandomWalkAwayTeamParams()),
    };
    break;
  }
  case BASEBALL_ID: {
    teamParamsConfig = merge({}, createBaseballHomeTeamParams(), createBaseballAwayTeamParams());
    break;
  }
  default: teamParamsConfig = null;
  }
  return teamParamsConfig;
};

export const getObjectMappableKeys = (obj, mappableKeys = [], mappableKey = '', nonMappable = false) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getObjectMappableKeys(obj[key], mappableKeys, !mappableKey ? key : `${mappableKey}.${key}`, obj[key]?.nonMappable);
    } else if (!includes(mappableKeys, mappableKey) && !nonMappable) {
      mappableKeys.push(mappableKey);
    }
  });
  return mappableKeys?.length ? mappableKeys : null;
};

const mapParamsSetupBaseballGameParams = (gameParams, gameParamsConfig, paramsDefaultOrProjected) => reduce(
  gameParamsConfig,
  (allParams, paramConfig, key) => {
    const paramsToAdd = {};
    const objectMappableKeys = getObjectMappableKeys(paramConfig, [], key);
    forEach(objectMappableKeys, (mappableKey) => {
      const param = get(gameParamsConfig, mappableKey);
      const customMappableKey = param?.customMappableKey;
      const paramValue = customMappableKey ? get(gameParams, customMappableKey) : get(gameParams, mappableKey);
      const isError = (paramValue < param?.minValue || paramValue > param?.maxValue) && !param.readonly;
      const errorMessage = `${param?.shortName} must be a value between ${param?.minValue} and ${param?.maxValue}`;
      set(paramsToAdd, mappableKey, ({
        ...param,
        value: paramValue,
        isError,
        errorMessage,
        originalValue: paramsDefaultOrProjected ? null : paramValue,
        mappableKey,
      }
      ));
    });
    return {
      ...allParams,
      ...paramsToAdd,
    };
  }, {},
);

export const mapPlayerSetupGameParams = (gameParams, sportId, paramsDefaultOrProjected) => {
  if (!gameParams) return null;
  const gameParamsConfig = {
    ...getGlobalParamsConfigBySport(sportId, gameParams?.modelType) || {},
    ...getTeamParamsConfigBySport(sportId, gameParams?.modelType) || {},
  };

  // For baseball
  if (sportId === BASEBALL_ID) {
    return mapParamsSetupBaseballGameParams(gameParams, gameParamsConfig, paramsDefaultOrProjected);
  }

  // For hockey, basketball and football
  const gameParamsMapped = [];
  const gameParamsKeys = keys(gameParamsConfig);
  forEach(gameParamsKeys, (paramKey) => {
    const param = cloneDeep(gameParamsConfig[paramKey]);
    const paramValue = gameParams[paramKey];
    const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
    const errorMessage = `${param?.shortName} must be a value between ${param?.minValue} and ${param?.maxValue}`;
    gameParamsMapped.push({
      value: paramValue,
      originalValue: paramsDefaultOrProjected ? null : paramValue,
      isError,
      errorMessage,
      key: paramKey,
      ...param,
    });
  });

  return gameParamsMapped;
};

export const mapPlayerSetupGameParamsSocketUpdate = (gameParams, updatedGameParams) => reduce(
  gameParams,
  (allParams, param) => {
    const paramCopy = cloneDeep(param);
    const newVal = updatedGameParams[param.key];
    paramCopy.value = newVal;
    paramCopy.originalValue = newVal;
    paramCopy.isError = newVal < paramCopy?.minValue || newVal > paramCopy?.maxValue;

    return [
      ...allParams,
      ...[paramCopy],
    ];
  }, [],
);

export const mapPlayerSetupGameParamsSubmitPayload = (gameParams, sportId) => {
  const paramsForPayload = reduce(gameParams,
    (allParams, param) => ({
      ...allParams,
      ...{ [param.key]: param.value },
    }), {});

  if (sportId === FOOTBALL_ID) {
    // For AF if overrideEventState is true, we need to send all the params
    // If not we should delete override params
    if (!paramsForPayload.overrideEventState) {
      delete paramsForPayload.overrideDownNumber;
      delete paramsForPayload.overrideYardsToWinDown;
      delete paramsForPayload.overrideYardsToEndZone;
      delete paramsForPayload.overridePossession;
    } else if (!paramsForPayload.overridePossession) {
      // If no value for is set for overridePossession, we should set it to NEUTRAL
      paramsForPayload.overridePossession = 'NEUTRAL';
    }
  }
  return paramsForPayload;
};

export const mapPlayerSetupBaseballGameParams = (obj, result = {}) => {
  forEach(toPairs(obj), (pair) => {
    const val = pair[1];
    if (isObject(val) && val.mappableKey) {
      const { customMappableKey } = val;
      if (customMappableKey) {
        set(result, customMappableKey, val.value);
      } else {
        set(result, val.mappableKey, val.value);
      }
    }
    if (isObject(val)) {
      mapPlayerSetupBaseballGameParams(val, result);
    }
  });
  return result;
};

// HELPERS
export const getGlobalParamsKeysBySport = (sportId, modelType) => {
  let globalParamsConfig = {};
  switch (sportId) {
  case HOCKEY_ID: {
    globalParamsConfig = createHockeyGlobalParams();
    break;
  }
  case BASKETBALL_ID: {
    globalParamsConfig = modelType === 'cruncher' ? createBasketballCruncherGlobalParams() : createBasketballRandomWalkGlobalParams();
    break;
  }
  case FOOTBALL_ID: {
    globalParamsConfig = createFootballGlobalParams();
    break;
  }
  case BASEBALL_ID: {
    globalParamsConfig = createBaseballGlobalParams();
    break;
  }
  default: globalParamsConfig = null;
  }

  return keys(globalParamsConfig) || [];
};

export const getTeamParamsKeysByTeamAndSport = (teamLabel, sportId, modelType) => {
  let globalParamsConfig = {};
  if (!teamLabel) return [];

  switch (sportId) {
  case HOCKEY_ID: {
    globalParamsConfig = teamLabel === 'Home' ? createHockeyHomeTeamParams() : createHockeyAwayTeamParams();
    break;
  }
  case FOOTBALL_ID: {
    globalParamsConfig = teamLabel === 'Home' ? createFootballHomeTeamParams() : createFootballAwayTeamParams();
    break;
  }

  case BASKETBALL_ID: {
    // eslint-disable-next-line no-nested-ternary
    globalParamsConfig = teamLabel === 'Home'
      ? (modelType === 'cruncher' ? createBasketballCruncherHomeTeamParams() : createBasketballRandomWalkHomeTeamParams())
      : (modelType === 'cruncher' ? createBasketballCruncherAwayTeamParams() : createBasketballRandomWalkAwayTeamParams());
    break;
  }
  default: globalParamsConfig = null;
  }

  return keys(globalParamsConfig) || [];
};

const findAvailableMarket = (availableMarkets, market) => find(availableMarkets, (allMarketsItem) => (
  allMarketsItem.marketCode === market.marketCode
  && allMarketsItem.params?.TEAM === market.params?.TEAM
  && allMarketsItem.params?.PERIOD === market.params?.PERIOD
  && allMarketsItem.params?.HALF === market.params?.HALF));

export const getParamsManagerMarketsBySport = (availableMarkets, sportId, offeringStatuses, isUSAView) => {
  let marketsBySport = [];

  switch (sportId) {
  case HOCKEY_ID: {
    marketsBySport = hockeyParamsManagerMarkets(isUSAView);
    break;
  }
  case BASKETBALL_ID: {
    marketsBySport = basketballParamsManagerMarkets(isUSAView);
    break;
  }
  case FOOTBALL_ID: {
    marketsBySport = footballParamsManagerMarkets(isUSAView);
    break;
  }
  case BASEBALL_ID: {
    marketsBySport = baseballParamsManagerMarkets(isUSAView);
    break;
  }
  default: marketsBySport = null;
  }
  marketsBySport = map(marketsBySport, (market) => {
    const foundMarket = findAvailableMarket(availableMarkets, market);
    if (!foundMarket) return market;
    return {
      ...market,
      ...foundMarket,
    };
  });
  return orderBy(map(marketsBySport, (marketBySport) => {
    const isSuspended = find(offeringStatuses, (market) => {
      const marketType = market?.marketTypeWithoutLine;
      return (
        marketType?.marketCode === marketBySport.marketCode
        && marketType?.params?.SELECTIONS === marketBySport?.params?.SELECTIONS
        && marketType?.params?.HALF === marketBySport?.params?.HALF
        && marketType?.params?.TEAM === marketBySport?.params?.TEAM
        && marketType?.params?.RESULT_AFTER_X === marketBySport?.params?.RESULT_AFTER_X
      );
    });
    return {
      ...marketBySport,
      suspended: isSuspended,
    };
  }), 'order');
};

export const isTooltipPositionLeft = (containerId, itemId) => {
  if (!itemId) return null;
  const container = document.getElementById(containerId);
  const item = document.getElementById(itemId);
  if (!item) return null;
  const itemRect = item.getBoundingClientRect();
  const position = {};
  if (itemRect.left >= container.offsetWidth / 2) {
    position.isLeft = true;
  }
  return position;
};

export const isBaseballGameParamsError = (params) => some(params, (val) => {
  if (isObject(val)) {
    return val.isError === true || isBaseballGameParamsError(val);
  }
  return false;
});

export const isBaseballGameParamsChanged = (params) => some(params, (val) => {
  if (isObject(val)) {
    return (val.value !== val.originalValue) || isBaseballGameParamsChanged(val);
  }
  return false;
});

// Helper function that will reorder the players position value when a player is moved
export const updateTeamPlayersPositionValue = (players, updatedPlayerId, updatedPlayerValue, oldValue) => {
  if (!updatedPlayerValue) return players;
  const hasPlayerWithSamePosition = !!find(players, (player) => player.playerPositionIndex === updatedPlayerValue);
  if (!hasPlayerWithSamePosition) return players;
  const playersDataByTeam = cloneDeep(players);

  const valueIncremented = updatedPlayerValue > oldValue;
  // If the value is decremented we only switch the value of the the player that has the same value as the changed one, with the previous value of the changed player
  // This is the case only when the difference between the old value and the updated value is 1
  if (!valueIncremented && oldValue - updatedPlayerValue === 1) {
    const playerToUpdateIndex = findIndex(playersDataByTeam, (player) => player.playerPositionIndex === updatedPlayerValue);
    playersDataByTeam[playerToUpdateIndex] = {
      ...playersDataByTeam[playerToUpdateIndex],
      playerPositionIndex: oldValue,
    };
    return playersDataByTeam;
  }
  // If the value is incremented we need to increment the value of all the players that are between the updated player and the next player with a higher value
  // We update all the players with the highter value until we find a player with a value that is not consecutive
  let playersToUpdate = orderBy(
    filter(
      playersDataByTeam,
      (player) => player.playerId !== updatedPlayerId
      && player.playerPositionIndex >= updatedPlayerValue,
    ), 'playerPositionIndex',
  );
  const endIndex = findIndex(playersToUpdate, ({ playerPositionIndex }, i) => playersToUpdate[i + 1]?.playerPositionIndex - playerPositionIndex > 1);
  if (endIndex !== -1) {
    playersToUpdate = playersToUpdate.slice(0, endIndex + 1);
  }
  const playersToUpdateIds = map(playersToUpdate, 'playerId');
  forEach(playersToUpdateIds, (playerId) => {
    const playerIndex = findIndex(playersDataByTeam, (player) => player.playerId === playerId);
    const playerData = cloneDeep(playersDataByTeam[playerIndex]);
    playersDataByTeam[playerIndex] = {
      ...playerData,
      playerPositionIndex: playerData.playerPositionIndex + 1,
    };
  });
  return playersDataByTeam;
};
